import { db } from "../config/firebase";

// Load results from firebase
export const loadResults = ({ dispatch }) => {
  // TODO: switch from client to server
  // db.collection("locataires")
  //   .get()
  //   .then((snapshot) => {
  //     dispatch({ type: "SET_PARTICIPANTS", value: snapshot.size });
  //     let csv = "Nom,Mobile,Réference locataire,Date\n";
  //     snapshot.forEach((doc) => {
  //       csv += `${doc.data().fullName},${doc.data().numTel},${
  //         doc.data().refLoc
  //       },${doc.data().dateCreation}`;
  //       csv += "\n";
  //     });
  //     console.log(csv);
  //   })
  //   .catch((err) => console.log(err));

  // Get all the participants with a valid code
  const codes = [
    "504009",
    "758616",
    "144308",
    "920166",
    "446640",
    "613127",
    "505654",
    "745511",
    "558014",
    "810413",
    "175133",
    "217003",
    "627200",
    "956033",
    "962064",
    "394572",
    "491856",
    "701273",
    "449075",
    "120349",
    "203359",
    "889947",
    "640262",
    "145902",
    "356156",
    "512394",
    "873278",
    "423295",
    "595313",
    "238576",
    "800832",
    "148537",
    "416596",
    "891047",
    "475629",
    "841478",
    "731249",
    "840704",
    "916661",
    "617211",
    "891430",
    "112277",
    "177130",
    "472515",
    "943143",
    "462543",
    "662745",
    "446043",
    "336892",
    "632140",
    "858945",
    "527111",
    "487599",
    "103564",
    "374727",
    "921144",
    "206293",
    "263065",
    "154371",
    "402325",
    "931849",
    "626753",
    "908113",
    "168362",
    "143792",
    "758377",
    "271078",
    "415464",
    "483577",
    "795484",
    "420748",
    "852275",
    "177541",
    "622536",
    "838812",
    "691157",
    "507081",
    "847240",
    "221182",
    "285206",
    "199256",
    "874210",
    "240265",
    "965611",
    "705210",
    "538428",
    "846175",
    "958976",
    "999434",
    "880777",
    "930111",
    "198534",
    "549751",
    "946570",
    "858811",
    "505703",
    "500458",
    "578828",
    "247700",
    "884165",
    "271301",
    "940493",
    "273035",
    "442894",
    "200388",
    "373576",
    "826320",
    "177762",
    "526304",
    "568749",
    "182116",
    "301172",
    "386248",
    "325658",
    "547662",
    "479301",
    "998055",
    "742818",
    "627168",
    "114707",
    "411527",
    "421934",
    "986289",
    "439242",
    "598492",
    "668527",
    "971007",
    "680364",
    "677569",
    "947921",
    "947581",
    "381119",
    "762383",
    "402347",
    "913100",
    "506680",
    "585532",
    "653990",
    "759764",
    "392693",
    "805232",
    "203458",
    "809628",
    "443021",
    "767943",
  ];
  const names = [
    "BOUKROUNE FARIDA",
    "BOULOUDEN MOHAMED",
    "CHTOUKAT LEILA BENGHAZALE YASSINE & BENGHAZALE",
    "SANGARE ASSA",
    "RICHAUD JEANJEAN CLAUDINE",
    "MEZZI MOHAND",
    "AMRANE  MOHAND SAID",
    "AMRANE MEDJID",
    "PENTECOTE NICOLE",
    "EL GANZRI JAMILA",
    "MOUTEAU MONIQUE",
    "SOW MARIEME ET MR",
    "RIAHI ZIED & RIAHI RIHEM",
    "MOSANGE EKWANGA INC",
    "VILLEMAINE ODETTE",
    "BENACHOUR LINDA",
    "POZZI NIBYA",
    "AFLALO LINDA",
    "BEN JABER KHEMOUSSI JABRI JALILA",
    "BUISSON BEATRICE",
    "DIABATE MAMADOU & KANTE BINTOU",
    "REBOURG SOPHIE",
    "GARCIA ANDRES",
    "MOUSTAPHAOUI ET MME",
    "MOUHAMED ASRAF & MOUHAMED AZZA",
    "BENCHIKH-LARABI HAKIMA LARABI MALEK",
    "BINTODI ALAIN & BINTODI ANNIE",
    "DUCOIN ROGER",
    "CHARPENTIER CHRISTINE",
    "BENISTI JULIEN",
    "SERET MARIE-JOSE",
    "SARAIVA MARIO",
    "PLAINCHAULT ERIC",
    "HAROUN OURDIA & BEHAR NOUREDINE",
    "KERMADI ZAOUA",
    "DIALLO AMARA",
    "TRAORE NOSSA",
    "SALDIN BELKHEIR GRIVOTET DOLORES",
    "COHEN SALOMON",
    "CARTIER FRANCIS",
    "MORA LUCIE",
    "GUISSE WORKHYA & GUISSE HAROUNA",
    "BOTTIER ELODIE",
    "OULAI DEHE MAWA",
    "KACI YAHIA ET MME",
    "HAMOUCHENE LEILA HAMOUCHENE YAHIA",
    "OUAHABI FATNA",
    "OUKACI SANAA OUKACI MOHAND SEGHIR",
    "LECUYER ERIC LECUYER MALIKA",
    "HADDAD ESTHER LUCIENNE",
    "FLEURY JEAN PAUL OU MME FLEURY NICOLE",
    "VIEIRA CELINE",
    "KALILA SIHAM",
    "MIRANDA Odile",
    "EXANTUS MARTHA",
    "DAVIDOVIC TATJANA",
    "BENISTI REGINE",
    "BENISTI SOPHIE",
    "JOLY CORINNE",
    "GAKOU FATOUMATA & GAKOU IDRISSA",
    "LADNER MARINA",
    "HETTIARACHCHI SARATHNANDI HETTIARACHCHI JAYANTHA",
    "FALASCA ALFRED ET MARIA",
    "EL MELLAHI ET MME",
    "ENGLER CHARLES",
    "DEFORCHE PRISCA CRISTOFARI BRUNO",
    "BOUZAGLO SALOMON BOUZAGLO ANNIE",
    "BENMEBAREK RACHID",
    "MESSAI BECHIR ET MME",
    "LAIR MARION",
    "SOW ALPHA ET FATOUMATA",
    "CHERIF HADRIA LEILA",
    "ESSOR",
    "MINATCHY MARIE-LOUISE",
    "HELLE VIVIANE",
    "THOMASEAU MURIEL",
    "JEANNE-MARIE FELCE",
    "ZERMANI ET MME",
    "SABBAH ALBERT",
    "MIRABELLO JOSY",
    "FABIANO MARIA FABIANO JEAN-CLAUDE",
    "HADJ-KACI DJEDJIGA",
    "SCHOTT SEBASTIEN",
    "MAMMAR BELAID ET MME",
    "CHWEDORUK ANNA",
    "ALLAVO Elisabeth",
    "BOUZAGLO JOSETTE",
    "DOUMBIA BINTOU",
    "BELLAICHE JEANINE",
    "DURAND E./MLE DEPUILLE",
    "BOLOU IRIE ANNIE",
    "DRIS-DARRICAU ZINEB",
    "QUINET FRANCOISE QUINET REMY",
    "DOUKARA NDEYE & DOUKARA BILALY",
    "BOULLIAUX CHRISTIAN",
    "SOUMAH ET MR.",
    "VALETUDIE ET MME",
    "CAVIGLIOLI ROSE",
    "TAVARES SANCHES MARIA",
    "SAIDANI KARIM",
    "MARTIN TEJEDOR FLORENCIO",
    "SOUMARE EL HACEN CHEIK SOUMARE AISSATA",
    "CHERQUI WILLIAMS ET MME",
    "DARGAHI PARVINE",
    "MANENA AWOUNFACK THERESE",
    "MAMONA SIALA SIALA MAMONA SIALA LEONNIE",
    "MAGNANI MANUEL MULET AVELLA STEFANY",
    "LAMTOUEL LAHCEN",
    "YOUSFI Souad et Mohammed",
    "GOUMONT FABRICE",
    "LAINE ELZBIETA",
    "GANEM-SEBBAN VALERIE SEBBAN RENE",
    "ALBERTINI JEANINE",
    "OUBELLA MARIE & RICHEUX MONIQUE",
    "CARINI Joseph et Marie",
    "CHOUIKHA-BOULCENE Sami et Laila",
    "MENTOURI RACHIDA",
    "DOUCOURE FATOUMATA DOUCOURE KALILOU",
    "JAMMET DANIELLE",
    "MINTHE KADIDIATOU",
    "GODEFROY FABRICE AIDDID KEDIDJA",
    "REBAÏ ISMAÏL REBAÏ-SELLAMI SIHAM",
    "LA PEPINIERE MATHIS",
    "PROJET 19",
    "BARBRY VALERIE",
    "JASAR SUNCICA",
    "BENHARKAT LAKHDAR",
    "DESCHASEAUX FLORENCE",
    "KIM CHUY KIM CHUY ALEXANDRA",
    "AGBOVOR YANNICK",
    "VANIE BI NAHOUE SERGE",
    "HUYNH ENG KHUN & THAI HELENE",
    "TAMBADOU MANTHITA",
    "ETIENNE ROSELINE",
    "GAMELLA MEKONG SERAPHIN SEBERA THERESE",
    "METTEF YAMINA",
    "NAUD GREGOIRE",
    "ARNOUX MONIQUE",
    "ADDA ELIANE",
    "CAMPISI CYRIL",
    "BA TAH & BA NOUHOUME",
    "BLEAUMELEN AURELIE",
    "TAHANOUT DAVID",
    "ABITBOL E./MLE ALLOUCHE S.",
    "DUREL PAUL",
  ];
  const aparts = [
    "R335L-0002",
    "R335L-0003",
    "R335L-0004",
    "R335L-0005",
    "R335L-0006",
    "R335L-0007",
    "R335L-0008",
    "R335L-0009",
    "R335L-0010",
    "R335L-0011",
    "R335L-0012",
    "R335L-0013",
    "R335L-0014",
    "R335L-0015",
    "R335L-0016",
    "R335L-0017",
    "R335L-0018",
    "R335L-0019",
    "R335L-0020",
    "R335L-0021",
    "R335L-0022",
    "R335L-0023",
    "R335L-0024",
    "R335L-0025",
    "R335L-0026",
    "R335L-0027",
    "R335L-0028",
    "R335L-0029",
    "R335L-0030",
    "R335L-0031",
    "R335L-0033",
    "R335L-0034",
    "R335L-0035",
    "R335L-0036",
    "R335L-0037",
    "R335L-0038",
    "R335L-0039",
    "R335L-0040",
    "R335L-0041",
    "R335L-0042",
    "R335L-0043",
    "R335L-0044",
    "R335L-0045",
    "R335L-0046",
    "R335L-0047",
    "R335L-0048",
    "R335L-0049",
    "R335L-0050",
    "R335L-0051",
    "R335L-0052",
    "R335L-0053",
    "R335L-0054",
    "R335L-0055",
    "R335L-0056",
    "R335L-0057",
    "R335L-0058",
    "R335L-0059",
    "R335L-0060",
    "R335L-0061",
    "R335L-0062",
    "R335L-0063",
    "R335L-0064",
    "R335L-0065",
    "R335L-0066",
    "R335L-0067",
    "R335L-0068",
    "R335L-0069",
    "R335L-0070",
    "R335L-0071",
    "R335L-0072",
    "R335L-0073",
    "R335L-0074",
    "R335L-0075",
    "R335L-0076",
    "R335L-0077",
    "R335L-0078",
    "R335L-0079",
    "R335L-0080",
    "R335L-0081",
    "R335L-0082",
    "R335L-0083",
    "R335L-0084",
    "R335L-0085",
    "R335L-0086",
    "R335L-0087",
    "R335L-0088",
    "R335L-0089",
    "R335L-0090",
    "R335L-0091",
    "R335L-0092",
    "R335L-0093",
    "R335L-0094",
    "R335L-0095",
    "R335L-0096",
    "R335L-0097",
    "R335L-0098",
    "R335L-0099",
    "R335L-0100",
    "R335L-0101",
    "R335L-0102",
    "R335L-0103",
    "R335L-0104",
    "R335L-0105",
    "R335L-0106",
    "R335L-0107",
    "R335L-0108",
    "R335L-0109",
    "R335L-0110",
    "R335L-0111",
    "R335L-0112",
    "R335L-0113",
    "R335L-0114",
    "R335L-0115",
    "R335L-0116",
    "R335L-0117",
    "R335L-0118",
    "R335L-0119",
    "R335L-0120",
    "R335L-0121",
    "R335L-0122",
    "R335L-0123",
    "R335L-0124",
    "R335L-9001",
    "R335L-9001/9002",
    "3702L-0001",
    "3702L-0002",
    "3702L-0003",
    "3702L-0004",
    "3702L-0005",
    "3702L-0006",
    "3702L-0007",
    "3702L-0008",
    "3702L-0009",
    "3702L-0010",
    "3702L-0011",
    "3702L-0012",
    "3702L-0013",
    "3702L-0014",
    "3702L-0015",
    "3702L-0016",
    "3702L-0017",
    "3702L-0018",
    "3702L-0019",
    "3702L-0020",
    "3702L-0021",
  ];

  let locData = [];

  // codes.map((code, index) => {
  //   locData.push({
  //     name: names[index],
  //     aparts: aparts[index],
  //     code: code,
  //     date: "",
  //   });
  // });

  const getParticipants = async (codes) => {
    await codes.map((code, index) => {
      var participantsQuery = db.collection("locataires");
      participantsQuery = participantsQuery.where("refLoc", "==", code);
      participantsQuery
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            locData.push({
              name: names[index],
              apart: aparts[index],
              code: code,
              date: doc.data().dateCreation,
            });
          });
        })
        .then(() => {
          let csv = "Lot,Locataire,Code,Date\n";
          locData.map((loc) => {
            csv += `${loc.apart},${loc.name},${loc.code},${loc.date.slice(
              0,
              19
            )}`;
            csv += "\n";
          });
          console.log(csv);
        })
        .catch((err) => console.log(err));
    });
    return locData;
  };

  getParticipants(codes)
    .then((locData) => {
      console.log(locData);
    })
    .then(() => {
      let csv = "Lot,Locataire,Code,Date\n";
      locData.map((loc) => {
        csv += `${loc.aparts},${loc.name},${loc.code},${loc.date}`;
        csv += "\n";
      });
      console.log(csv);
    });

  // db.collection("resultats")
  //   .get()
  //   .then((snapshot) => {
  //     dispatch({ type: "SET_REPONSES", value: snapshot.size });
  //   })
  //   .catch((err) => console.log(err));
};
