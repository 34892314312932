import React from "react";
import "./App.css";
// Pages / Containers
import Login from "./containers/Login/Login";
import Home from "./containers/Home/Home";
import ProfileSetup from "./containers/ProfileSetup/ProfileSetup";
import Travaux from "./containers/Travaux/Travaux";
import ProfilePage from "./containers/ProfilePage/ProfilePage";
import Resultats from "./containers/Resultats/Resultats";
// Blog
import PageOne from "./containers/Blog/BlogPages/PageOne";
// Routing
import { BrowserRouter as Router, Route } from "react-router-dom";
import PrivateRoute from "./config/PrivateRoute";
// Contexts
import { AuthProvider } from "./contexts/AuthContext";
import ProfileSetupContextProvider from "./contexts/ProfileSetupContext";
import ContactContextProvider from "./contexts/ContactContext";
import LoginContextProvider from "./contexts/LoginContext";
import TravauxContextProvider from "./contexts/TravauxContext";
import ResultatsContextProvider from "./contexts/ResultatsContext";
// Navigation
// import Layout from "./hoc/Layout/Layout";

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
          {/* <PrivateRoute exact path="/" component={Home} /> */}
          <ContactContextProvider>
            <Route exact path="/" component={Home} />
          </ContactContextProvider>
          <LoginContextProvider>
            <Route exact path="/login" component={Login} />
          </LoginContextProvider>
          <ProfileSetupContextProvider>
            <Route exact path="/profile-setup" component={ProfileSetup} />
            <PrivateRoute exact path="/my-profile" component={ProfilePage} />
          </ProfileSetupContextProvider>
          <TravauxContextProvider>
            <PrivateRoute exact path="/travaux" component={Travaux} />
          </TravauxContextProvider>
          <Route exact path="/blog/1" component={PageOne} />
        </div>
        <ResultatsContextProvider>
          <PrivateRoute exact path="/resultats" component={Resultats} />
        </ResultatsContextProvider>
      </Router>
    </AuthProvider>
  );
}

export default App;
