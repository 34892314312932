import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const app = firebase.initializeApp({
  apiKey: "AIzaSyB3YpkAWIWMF-zByK6z8D2w15dzYWNMAME",
  authDomain: "mathis-demo.firebaseapp.com",
  databaseURL: "https://projet-homy.firebaseio.com",
  projectId: "projet-homy",
  storageBucket: "projet-homy.appspot.com",
  messagingSenderId: "259960351594",
});

export const db = app.firestore().collection("projets").doc("mathis-demo");

// const app = firebase.initializeApp({
//   apiKey: process.env.REACT_APP_FIREBASE_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
//   databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID
// });

export default app;
