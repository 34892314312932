import React from "react";

export const travauxCardsData = [
  {
    id: "c1",
    type: "concertable",
    layout: "concertableLayoutOne",
    submitted: false,
    titleStrong: "Définir les usages",
    titleWeak: "des toitures-terrasses",
    description:
      "Aujourd’hui, la toiture-terrasse de votre immeuble n’est pas utilisée. Demain, grâce aux travaux, elle pourrait avoir une utilité et cet espace vide pourrait devenir quelque chose qui profite à tous les résidents ! Devenir un lieu de rencontre, un jardin suspendu… Les possibilités sont nombreuses et pour cela nous avons besoin de votre avis !",
    thumbnail: "c1_q1rep1.png",
    imgSrc: "PleaseSelect.png",
    planMasse: "illustrationAttente.png",
    tags: ["personnalisable"],
    conditional: {
      id: "conditional",
      type: "conditional",
      title:
        "Seriez-vous prêt à vous impliquer dans la création ou l’entretien d’un jardin partagé ?",
      options: {
        q1rep1: {
          label: "Oui",
          value: "q1rep1",
        },
        q1rep2: {
          label: "Non",
          value: "q1rep2",
        },
      },
      isConditional: false,
      value: false,
    },
    questions: [
      {
        id: "q1",
        type: "radio",
        title:
          "Quel type d’usage verriez-vous sur les toitures-terrasses ? (N’hésitez pas à donner vos propres options si celles proposées ne vous conviennent pas.)",
        options: [
          {
            label: "La toiture comme une cinquième façade végétalisée.",
            value: "q1rep1",
          },
          {
            label: "Un lieu de détente et de rencontre. ",
            value: "q1rep2",
          },
          {
            label: "Un lieu de culture pour les plantes et les légumes.",
            value: "q1rep3",
          },
          {
            label: "Un jardin suspendu.",
            value: "q1rep4",
          },
        ],
        value: "",
      },
    ],
    comment: "",
    noticeOne: "* Sous réserve des résultats des études structure",
    noticeTwo: "** Sous condition de création d’un accès indépendant",
  },
  {
    id: "c2",
    type: "concertable",
    layout: "concertableLayoutOne",
    submitted: false,
    titleStrong: "Le remplacement",
    titleWeak: "des portes palières",
    description:
      "Votre bailleur souhaite refaire toutes les parties communes et remplacer toutes les portes palières de l’immeuble. Pour cela, votre coopération est requise afin de définir l’ambiance générale, le type de serrurerie ou encore la personnalisation des numéros d’appartement. Ces travaux permettront d’améliorer la sécurité, améliorer la qualité acoustique et le coupe feu de votre résidence.",
    thumbnail: "c2_q1rep1_q2rep1_q3rep1.png",
    imgSrc: "PleaseSelect.png",
    planMasse: "illustrationAttente.png",
    tags: ["personnalisable"],
    conditional: {
      id: "conditional",
      type: "conditional",
      title: "",
      options: {
        q1rep1: {
          label: "Oui",
          value: "q1rep1",
        },
        q1rep2: {
          label: "Non",
          value: "q1rep2",
        },
      },
      isConditional: false,
      value: false,
    },
    questions: [
      {
        id: "q1",
        type: "radio",
        title:
          "Quel type d’ambiance souhaiteriez-vous pour les parties communes de l’immeuble ?",
        options: [
          {
            label: "Ambiance pastelle",
            thumbnail: "illustrationAttente.png",
            value: "q1rep1",
          },
          {
            label: "Ambiance énergique",
            thumbnail: "illustrationAttente.png",
            value: "q1rep2",
          },
          {
            label: "Ambiance sobre",
            thumbnail: "illustrationAttente.png",
            value: "q1rep3",
          },
          {
            label: "Ambiance chic",
            thumbnail: "illustrationAttente.png",
            value: "q1rep4",
          },
          {
            label: "Ambiance du sud",
            thumbnail: "illustrationAttente.png",
            value: "q1rep5",
          },
        ],
        value: "q1rep1",
      },
      {
        id: "q2",
        type: "radioImage",
        title:
          "Quel type de poignée souhaiteriez-vous pour votre porte d’entrée ?",
        options: [
          {
            label: "Classique",
            thumbnail: "miniatures/c2_q2thumb1.png",
            value: "q2rep1",
          },
          {
            label: "Bouton de tirage central",
            thumbnail: "miniatures/c2_q2thumb2.png",
            value: "q2rep2",
          },
        ],
        value: "q2rep1",
      },
      {
        id: "q3",
        type: "radioImage",
        title:
          "Quel type de numérotation d’appartement souhaiteriez-vous sur votre porte palière ?",
        options: [
          {
            label:
              "Votre numéro d’appartement poché (peint directement sur la porte).",
            thumbnail: "miniatures/c2_q3thumb1.png",
            value: "q3rep1",
          },
          {
            label: "Une plaque avec le numéro de votre appartement.",
            thumbnail: "miniatures/c2_q3thumb2.png",
            value: "q3rep2",
          },
        ],
        value: "q3rep1",
      },
    ],
    comment: "",
    noticeOne:
      "* Le choix d’aménagement se fera à la majorité des locataires exprimés grâce à cette application.",
  },
  {
    id: "c3",
    type: "concertable",
    layout: "concertableLayoutOne",
    submitted: false,
    titleStrong: "La révision",
    titleWeak: "ou le remplacement des volets métalliques.",
    description:
      "Les volets métalliques de votre logement sont aujourd’hui victimes de leur grand âge et par voie de conséquence la protection contre le soleil qu’ils assurent dans votre cuisine et votre salon est compromise.",
    thumbnail: "c3_q1rep1.png",
    imgSrc: "PleaseSelect.png",
    planMasse: "illustrationAttente.png",
    tags: ["personnalisable"],
    conditional: {
      id: "conditional",
      type: "conditional",
      title:
        "Souhaitez-vous un remplacement de vos volets métalliques dans votre logement ?",
      options: {
        q1rep1: {
          label: "Non, une simple révision suffira",
          value: "q1rep1",
        },
        q1rep2: {
          label: "Oui",
          value: "q1rep2",
        },
      },
      isConditional: true,
      value: false,
    },
    questions: [
      {
        id: "q1",
        type: "radioImage",
        title:
          "Quel système d’occultation préféreriez-vous pour votre salon et votre cuisine ?",
        options: [
          {
            label: "Store banne",
            thumbnail: "miniatures/c3_q1thumb1.png",
            value: "q1rep1",
          },
          {
            label: "Volet vénitien",
            thumbnail: "miniatures/c3_q1thumb2.png",
            value: "q1rep2",
          },
        ],
        value: "",
      },
    ],
    comment: "",
  },
  {
    id: "c4",
    type: "concertable",
    layout: "concertableLayoutOne",
    submitted: false,
    titleStrong: "Le remplacement",
    titleWeak: "des porte-fenêtres",
    description:
      "Les fenêtres qui composent votre logement et votre immeuble sont un poste important dans le projet de rénovation. Leur type, leur style ou encore leur matériau agiront sur la luminosité de votre logement, son confort thermique ou encore l’aspect esthétique de votre immeuble sur la rue.",
    thumbnail: "c4_q1rep1.png",
    imgSrc: "PleaseSelect.png",
    planMasse: "illustrationAttente.png",
    tags: ["personnalisable"],
    conditional: {
      id: "conditional",
      type: "conditional",
      title: "Disposez-vous d’une porte-fenêtre dans votre logement ?",
      options: {
        q1rep1: {
          label: "Non",
          value: "q1rep1",
        },
        q1rep2: {
          label: "Oui",
          value: "q1rep2",
        },
      },
      isConditional: true,
      value: false,
    },
    questions: [
      {
        id: "q1",
        type: "radio",
        title:
          "Quel système d’ouverture pour votre porte-fenêtre aurait votre préférence ?",
        options: [
          {
            label: "Un système avec double battant.",
            value: "q1rep1",
          },
          {
            label: "Un système à simple battant avec un pan fixe.",
            value: "q1rep2",
          },
        ],
        value: "",
      },
    ],
    comment: "",
    noticeOne:
      "* En accord avec les prescriptions architecturales de la Mairie de Paris",
    noticeTwo: "** Cette question ne concerne pas les baies vitrées du salon",
  },
  {
    id: "c5",
    type: "concertable",
    layout: "concertableLayoutOne",
    submitted: false,
    titleStrong: "Le remplacement",
    titleWeak: "des fenêtres de la cuisine",
    description:
      "Les fenêtres qui composent votre logement et votre immeuble sont un poste important dans le projet de rénovation. Leur type, leur style ou encore leur matériau agiront sur la luminosité de votre logement, son confort thermique ou encore l’aspect esthétique de votre immeuble sur la rue.",
    thumbnail: "c5_q1rep1.png",
    imgSrc: "PleaseSelect.png",
    planMasse: "illustrationAttente.png",
    tags: ["personnalisable"],
    conditional: {
      id: "conditional",
      type: "conditional",
      title: "Ouvrez-vous la fenêtre dans votre cuisine quand vous cuisinez ?",
      options: {
        q1rep1: {
          label: "Non",
          value: "q1rep1",
        },
        q1rep2: {
          label: "Oui",
          value: "q1rep2",
        },
      },
      isConditional: true,
      value: false,
    },
    questions: [
      {
        id: "q1",
        type: "radio",
        title:
          "Souhaiteriez-vous que l’on installe une fenêtre oscillant battant ? (Ce système est à un prix supérieur aux autres solutions et il est également plus fragile)",
        options: [
          {
            label: "Oui",
            value: "q1rep1",
          },
          {
            label: "Non",
            value: "q1rep2",
          },
        ],
        value: "",
      },
    ],
    comment: "",
    noticeOne:
      "* En accord avec les prescriptions architecturales de la Mairie de Paris.",
  },
  {
    id: "c6",
    type: "concertable",
    layout: "concertableLayoutOne",
    submitted: false,
    titleStrong: "Le remplacement",
    titleWeak: "du contrôle d’accès",
    description:
      "Le système de contrôle d’accès à votre immeuble sera revu afin de sécuriser les accès mais aussi le rendre plus simple à l’usage. Deux solutions sont proposées par votre bailleur : l’une filaire qui correspond à un interphone classique, l’autre par GSM qui vous permet d’ouvrir l’accès à l’immeuble aux visiteurs grâce à votre téléphone portable.",
    thumbnail: "c6_q1rep1.png",
    imgSrc: "PleaseSelect.png",
    planMasse: "illustrationAttente.png",
    tags: ["personnalisable"],
    conditional: {
      id: "conditional",
      type: "conditional",
      title: "",
      options: {
        q1rep1: {
          label: "Oui",
          value: "q1rep1",
        },
        q1rep2: {
          label: "Non",
          value: "q1rep2",
        },
      },
      isConditional: false,
      value: false,
    },
    questions: [
      {
        id: "q1",
        type: "radio",
        title: "Quel système de contrôle d’accès préféreriez-vous ?",
        options: [
          {
            label: "Un contrôle d’accès filaire",
            value: "q1rep1",
          },
          {
            label: "Un contrôle d’accès GSM",
            value: "q1rep2",
          },
        ],
        value: "",
      },
    ],
    comment: "",
  },
  {
    id: "c7",
    type: "concertable",
    layout: "concertableLayoutOne",
    submitted: false,
    titleStrong: "La réfection de la salle",
    titleWeak: "de bain et des WC",
    description:
      "Une attention toute particulière sera mise dans la rénovation des pièces humides, avec la problématique que tous les locataires n’ont pas sur ce poste les mêmes exigences. En effet, certains ont déjà fait des travaux d’aménagement, d’autres se retrouvent avec des équipements mal adaptés à leur situation ou encore certains locataires ont des exigences très particulières qu’il est difficile de généraliser à tous. C’est pour cela que votre avis est indispensable !",
    thumbnail: "c7_q1rep1_q2rep1.png",
    imgSrc: "PleaseSelect.png",
    planMasse: "illustrationAttente.png",
    tags: ["personnalisable"],
    conditional: {
      id: "conditional",
      type: "conditional",
      title:
        "Si vous avez déjà réalisé des travaux dans votre salle de bain ou WC, souhaiteriez-vous conserver vos propres aménagements ?",
      options: {
        q1rep1: {
          label: "Oui",
          value: "q1rep1",
        },
        q1rep2: {
          label: "Non",
          value: "q1rep2",
        },
      },
      isConditional: true,
      value: false,
    },
    questions: [
      {
        id: "q1",
        type: "radio",
        title:
          "Pour des raisons de confort, d’accessibilité et en vue d’un gain de place, souhaiteriez-vous remplacer votre baignoire par une douche ?",
        options: [
          {
            label:
              "Oui, je souhaiterais que ma baignoire soit remplacée par une douche.",
            value: "q1rep1",
          },
          {
            label:
              "Non, je souhaiterais garder une baignoire dans ma salle de bain.",
            value: "q1rep2",
          },
          {
            label: "Mon logement ne possède pas de baignoire.",
            value: "q1rep3",
          },
        ],
        value: "q1rep1",
      },
      {
        id: "q2",
        type: "radio",
        title:
          "Si vous avez des exigences particulières liées à un usage spécifique de votre salle de bain et / ou de vos WC, souhaitez-vous travailler conjointement avec les équipes d’architectes à son aménagement ?",
        options: [
          {
            label:
              "Oui, j’aimerais collaborer avec les architectes pour l’aménagement des pièces humides de mon logement.",
            value: "q2rep1",
          },
          {
            label: "Non, je ne suis pas intéressé.e",
            value: "q2rep2",
          },
        ],
        value: "q2rep1",
      },
    ],
    comment: "",
  },
  {
    id: "c8",
    type: "concertable",
    layout: "concertableLayoutOne",
    submitted: false,
    titleStrong: "La sécurisation",
    titleWeak: "de vos balcons.",
    description:
      "Les balcons de votre immeuble ont besoin d’être sécurisées pour éviter tout incident. Pour cela, les garde-corps des balcons seront remplacés. Au-delà de l’aspect sécuritaire, la nature des nouveaux garde-corps va influer sur la transparence de vos balcons et par conséquent sur la luminosité et la vue à l’intérieur de votre logement.",
    thumbnail: "c8_q1rep1.png",
    imgSrc: "PleaseSelect.png",
    planMasse: "illustrationAttente.png",
    tags: ["personnalisable"],
    conditional: {
      id: "conditional",
      type: "conditional",
      title: "",
      options: {
        q1rep1: {
          label: "Oui",
          value: "q1rep1",
        },
        q1rep2: {
          label: "Non",
          value: "q1rep2",
        },
      },
      isConditional: false,
      value: false,
    },
    questions: [
      {
        id: "q1",
        type: "radio",
        title: "Quel type de garde-corps souhaiteriez-vous pour vos balcons ?",
        options: [
          {
            label:
              "Des garde-corps complètement opaques pour qu’on ne puisse pas voir ma terrasse.",
            value: "q1rep1",
          },
          {
            label:
              "Des garde-corps plus transparents pour accroître la luminosité et la vue.",
            value: "q1rep2",
          },
          {
            label: "Un savant mélange des deux propositions.",
            value: "q1rep3",
          },
        ],
        value: "",
      },
    ],
    comment: "",
    noticeOne:
      "* Sous condition d’accord avec les prescriptions architecturales de la Mairie de Paris.",
  },
  {
    id: "c9",
    type: "concertable",
    layout: "concertableLayoutOne",
    submitted: false,
    titleStrong: "L’usage",
    titleWeak: "des locaux communs.",
    description:
      "Les locaux communs doivent être aménagés pour correspondre à vos besoins. C’est ici que vous intervenez : Exprimez vos attentes !",
    thumbnail: "c9_q1rep1.png",
    imgSrc: "PleaseSelect.png",
    planMasse: "illustrationAttente.png",
    tags: ["personnalisable"],
    conditional: {
      id: "conditional",
      type: "conditional",
      title: "",
      options: {
        q1rep1: {
          label: "Oui",
          value: "q1rep1",
        },
        q1rep2: {
          label: "Non",
          value: "q1rep2",
        },
      },
      isConditional: false,
      value: false,
    },
    questions: [
      {
        id: "q1",
        type: "radio",
        title:
          "Quels sont vos besoins d’aménagement des locaux communs parmi les choix proposés ?",
        options: [
          {
            label: "Un local poussette.",
            value: "q1rep1",
          },
          {
            label: "Un local vélo.",
            value: "q1rep2",
          },
          {
            label: "Les deux.",
            value: "q1rep3",
          },
        ],
        value: "",
      },
    ],
    comment: "",
  },
  {
    id: "nc1",
    type: "nonConcertable",
    layout: "nonConcertableLayoutTwo",
    titleStrong: "Mise en place",
    titleWeak: "d’une Isolation thermique par l’extérieur",
    description:
      "Cette intervention consiste à poser un manteau de laine minérale sur les façades des bâtiments. Comme un pull, l’isolant va permettre de garder la chaleur dans le bâtiment l’hiver, mais aussi la fraîcheur l’été. Pour poser ce manteau de laine minérale il est nécessaire de retirer la jolie façade en pierre, de mettre l’isolant sur le mur en béton. Le parement pierre pourra être ensuite reposé sur la façade. ",
    jsxDescription: (
      <React.Fragment>
        <p>
          Cette intervention consiste à poser un manteau de laine minérale sur
          les façades des bâtiments. Comme un pull, l’isolant va permettre de
          garder la chaleur dans le bâtiment l’hiver, mais aussi la fraîcheur
          l’été. Pour poser ce manteau de laine minérale il est nécessaire de
          retirer la jolie façade en pierre, de mettre l’isolant sur le mur en
          béton. Le parement pierre pourra être ensuite reposé sur la façade.
        </p>
      </React.Fragment>
    ),
    jsxNote: <p></p>,
    detailsTravaux: [
      {
        id: 1,
        emoji: "",
        detail: "(à determiner)",
      },
    ],
    thumbnail: "NC1Thumb.png",
    video: {
      url: "https://youtu.be/_faONokkScY",
      width: 320,
      height: 400,
    },
    planMasse: "illustrationAttente.png",
    planMasseTexte: "(à déterminer)",
    duration: "(à déterminer)",
    periode: "(à déterminer)",
    tags: ["informative"],
    submitted: false,
    questions: {},
    comment: "",
  },
  {
    id: "nc2",
    type: "nonConcertable",
    layout: "nonConcertableLayoutTwo",
    titleStrong: "Isolation",
    titleWeak: "et étanchéité de la toiture terrasse",
    description:
      "Cette intervention consiste à enlever ce qu’il y a en toiture (isolant et membrane d’étanchéité) pour poser une couche plus importante d’un nouvel isolant plus performant sur le béton de la toiture puis de venir recouvrir le tout d’une membrane bitumée afin de garantir l’étanchéité de la toiture. Cette intervention va permettre de réaliser des économies de chauffage mais aussi de contrer la descente d’humidité par la toiture dans les logements.",
    jsxDescription: (
      <React.Fragment>
        <p>
          Cette intervention consiste à enlever ce qu’il y a en toiture (isolant
          et membrane d’étanchéité) pour poser une couche plus importante d’un
          nouvel isolant plus performant sur le béton de la toiture puis de
          venir recouvrir le tout d’une membrane bitumée afin de garantir
          l’étanchéité de la toiture. Cette intervention va permettre de
          réaliser des économies de chauffage mais aussi de contrer la descente
          d’humidité par la toiture dans les logements.
        </p>
      </React.Fragment>
    ),
    jsxNote: <p></p>,
    detailsTravaux: [
      {
        id: 1,
        emoji: "",
        detail: "(à determiner)",
      },
    ],
    thumbnail: "NC2Thumb.png",
    video: {
      url: "https://youtu.be/-RSfI0pBTaE",
      width: 320,
      height: 400,
    },
    planMasse: "illustrationAttente.png",
    planMasseTexte: "(à déterminer)",
    duration: "(à déterminer)",
    periode: "(à déterminer)",
    tags: ["informative"],
    submitted: false,
    questions: {},
    comment: "",
  },
  {
    id: "nc3",
    type: "nonConcertable",
    layout: "nonConcertableLayoutTwo",
    titleStrong: "Réfection",
    titleWeak: "de la distribution de chauffage",
    description:
      "Avec le temps, l’acidité de l’eau, et le calcaire, les canalisations peuvent être rongées et se détériorer pouvant occasionner des fuites et une interruption de chauffage dans le pire des cas. Si les diagnostics identifient des détériorations les travaux consisteront à la découpe des réseaux verticaux situés principalement derrière les sanitaires dans les logements puis la pose de tuyaux neufs, l à la même place. Cette intervention permet de préserver un maximum les radiateurs de tout dommage intérieur et de soulager les pompes faisant monter l’eau dans les étages.",
    jsxDescription: (
      <React.Fragment>
        <p>
          Avec le temps, l’acidité de l’eau, et le calcaire, les canalisations
          peuvent être rongées et se détériorer pouvant occasionner des fuites
          et une interruption de chauffage dans le pire des cas. Si les
          diagnostics identifient des détériorations les travaux consisteront à
          la découpe des réseaux verticaux situés principalement derrière les
          sanitaires dans les logements puis la pose de tuyaux neufs, l à la
          même place. Cette intervention permet de préserver un maximum les
          radiateurs de tout dommage intérieur et de soulager les pompes faisant
          monter l’eau dans les étages.
        </p>
      </React.Fragment>
    ),
    jsxNote: <p></p>,
    detailsTravaux: [
      {
        id: 1,
        emoji: "",
        detail: "(à determiner)",
      },
    ],
    thumbnail: "NC3Thumb.png",
    video: {
      url: "https://youtu.be/sDG_Qi_KDf8",
      width: 320,
      height: 400,
    },
    planMasse: "illustrationAttente.png",
    planMasseTexte: "(à déterminer)",
    duration: "(à déterminer)",
    periode: "(à déterminer)",
    tags: ["informative"],
    submitted: false,
    questions: {},
    comment: "",
  },
  {
    id: "nc4",
    type: "nonConcertable",
    layout: "nonConcertableLayoutTwo",
    titleStrong: "Traitement ",
    titleWeak: "des menuiseries extérieures",
    description:
      "Cette intervention consiste à enlever les fenêtres en PVC actuelles et le cadre en bois des anciennes menuiseries à pour pouvoir poser les nouvelles fenêtres directement sur le mur et ainsi ne pas créer d’entrée d’air et d’humidité supplémentaire. Cela permettra d’améliorer le confort thermique dans les logements.",
    jsxDescription: (
      <React.Fragment>
        <p>
          Cette intervention consiste à enlever les fenêtres en PVC actuelles et
          le cadre en bois des anciennes menuiseries à pour pouvoir poser les
          nouvelles fenêtres directement sur le mur et ainsi ne pas créer
          d’entrée d’air et d’humidité supplémentaire. Cela permettra
          d’améliorer le confort thermique dans les logements.
        </p>
      </React.Fragment>
    ),
    jsxNote: <p></p>,
    detailsTravaux: [
      {
        id: 1,
        emoji: "",
        detail: "(à determiner)",
      },
    ],
    thumbnail: "NC4Thumb.png",
    video: {
      url: "https://youtu.be/tztfu3vmoqM",
      width: 320,
      height: 400,
    },
    planMasse: "illustrationAttente.png",
    planMasseTexte: "(à déterminer)",
    duration: "(à déterminer)",
    periode: "(à déterminer)",
    tags: ["informative"],
    submitted: false,
    questions: {},
    comment: "",
  },
  {
    id: "nc5",
    type: "nonConcertable",
    layout: "nonConcertableLayoutTwo",
    titleStrong: "Amélioration ",
    titleWeak: "de la loge gardien",
    description:
      "La loge gardien sera cloisonnée pour définir plusieurs espaces comme un espace d’attente, un espace de réunion et un point d’info. Le gardien pourra accueillir les locataires dans de bonnes conditions.",
    jsxDescription: (
      <React.Fragment>
        <p>
          La loge gardien sera cloisonnée pour définir plusieurs espaces comme
          un espace d’attente, un espace de réunion et un point d’info. Le
          gardien pourra accueillir les locataires dans de bonnes conditions.
        </p>
      </React.Fragment>
    ),
    jsxNote: <p></p>,
    detailsTravaux: [
      {
        id: 1,
        emoji: "",
        detail: "(à determiner)",
      },
    ],
    thumbnail: "NC5Thumb.png",
    video: {
      url: "https://youtu.be/PLjcDJFCI4g",
      width: 320,
      height: 400,
    },
    planMasse: "illustrationAttente.png",
    planMasseTexte: "(à déterminer)",
    duration: "(à déterminer)",
    periode: "(à déterminer)",
    tags: ["informative"],
    submitted: false,
    questions: {},
    comment: "",
  },
  {
    id: "nc6",
    type: "nonConcertable",
    layout: "nonConcertableLayoutTwo",
    titleStrong: "Amélioration ",
    titleWeak: "de l’accessibilité des parties communes",
    description:
      "Cette opération consiste à changer les ascenseurs pour les moderniser. La cage de l’ascenseur, les portes à chaque étage ainsi que le moteur en toiture vont être enlevés et remplacés. Les nouvelles portes s’ouvriront davantage pour laisser passer un fauteuil roulant sans encombre, les boutons seront à hauteur de tous et le système de sécurité sera renforcé. ",
    jsxDescription: (
      <React.Fragment>
        <p>
          Cette opération consiste à changer les ascenseurs pour les moderniser.
          La cage de l’ascenseur, les portes à chaque étage ainsi que le moteur
          en toiture vont être enlevés et remplacés. Les nouvelles portes
          s’ouvriront davantage pour laisser passer un fauteuil roulant sans
          encombre, les boutons seront à hauteur de tous et le système de
          sécurité sera renforcé.
        </p>
      </React.Fragment>
    ),
    jsxNote: <p></p>,
    detailsTravaux: [
      {
        id: 1,
        emoji: "",
        detail: "(à determiner)",
      },
    ],
    thumbnail: "NC6Thumb.png",
    video: {
      url: "https://youtu.be/sM731kz6m5A",
      width: 320,
      height: 400,
    },
    planMasse: "illustrationAttente.png",
    planMasseTexte: "(à déterminer)",
    duration: "(à déterminer)",
    periode: "(à déterminer)",
    tags: ["informative"],
    submitted: false,
    questions: {},
    comment: "",
  },
  {
    id: "nc7",
    type: "nonConcertable",
    layout: "nonConcertableLayoutTwo",
    titleStrong: "Isolation",
    titleWeak: "des planchers bas",
    description:
      "Cette opération consiste à projeter un isolant sur les plafonds des caves pour retenir un maximum la chaleur au rez-de-chaussée.",
    jsxDescription: (
      <React.Fragment>
        <p>
          Cette opération consiste à projeter un isolant sur les plafonds des
          caves pour retenir un maximum la chaleur au rez-de-chaussée.
        </p>
      </React.Fragment>
    ),
    jsxNote: <p></p>,
    detailsTravaux: [
      {
        id: 1,
        emoji: "",
        detail: "(à determiner)",
      },
    ],
    thumbnail: "NC7Thumb.png",
    video: {
      url: "https://youtu.be/nB5Q7bsC4hc",
      width: 320,
      height: 400,
    },
    planMasse: "illustrationAttente.png",
    planMasseTexte: "(à déterminer)",
    duration: "(à déterminer)",
    periode: "(à déterminer)",
    tags: ["informative"],
    submitted: false,
    questions: {},
    comment: "",
  },
  {
    id: "nc8",
    type: "nonConcertable",
    layout: "nonConcertableLayoutTwo",
    titleStrong: "Mise en conformité",
    titleWeak: "électrique des logements",
    description:
      "Cette opération consiste à sécuriser et protéger votre installation électrique et vos équipements. Les prises seront démontées et remplacées, des prises pourront être ajoutées dans certaines pièces. Des éléments de protection seront ajoutés ou remplacés dans le tableau électrique pour protéger vos équipements comme le lave-linge, lave vaisselles, micro-onde...",
    jsxDescription: (
      <React.Fragment>
        <p>
          Cette opération consiste à sécuriser et protéger votre installation
          électrique et vos équipements. Les prises seront démontées et
          remplacées, des prises pourront être ajoutées dans certaines pièces.
          Des éléments de protection seront ajoutés ou remplacés dans le tableau
          électrique pour protéger vos équipements comme le lave-linge, lave
          vaisselles, micro-onde...
        </p>
      </React.Fragment>
    ),
    jsxNote: <p></p>,
    detailsTravaux: [
      {
        id: 1,
        emoji: "",
        detail: "(à determiner)",
      },
    ],
    thumbnail: "NC8Thumb.png",
    video: {
      url: "https://youtu.be/Ooemv-0ySHg",
      width: 320,
      height: 400,
    },
    planMasse: "illustrationAttente.png",
    planMasseTexte: "(à déterminer)",
    duration: "(à déterminer)",
    periode: "(à déterminer)",
    tags: ["informative"],
    submitted: false,
    questions: {},
    comment: "",
  },
  {
    id: "nc9",
    type: "nonConcertable",
    layout: "nonConcertableLayoutTwo",
    titleStrong: "Remise du gaz",
    titleWeak: "de cuisson dans les logements",
    description:
      "Cette opération consiste à amener le gaz dans les cuisines des logements où la cuisson est électrique afin d’harmoniser l’ensemble des logements. Un tuyau de gaz sera déployé dans chaque logement via les réservations techniques des cuisines et un robinet sera installé pour la coupure du gaz.",
    jsxDescription: (
      <React.Fragment>
        <p>
          Cette opération consiste à amener le gaz dans les cuisines des
          logements où la cuisson est électrique afin d’harmoniser l’ensemble
          des logements. Un tuyau de gaz sera déployé dans chaque logement via
          les réservations techniques des cuisines et un robinet sera installé
          pour la coupure du gaz.
        </p>
      </React.Fragment>
    ),
    jsxNote: <p></p>,
    detailsTravaux: [
      {
        id: 1,
        emoji: "",
        detail: "(à determiner)",
      },
    ],
    thumbnail: "NC9Thumb.png",
    video: {
      url: "https://youtu.be/_p78HxBgFkM",
      width: 320,
      height: 400,
    },
    planMasse: "illustrationAttente.png",
    planMasseTexte: "(à déterminer)",
    duration: "(à déterminer)",
    periode: "(à déterminer)",
    tags: ["informative"],
    submitted: false,
    questions: {},
    comment: "",
  },
  {
    id: "nc10",
    type: "nonConcertable",
    layout: "nonConcertableLayoutTwo",
    titleStrong: "Mise en place",
    titleWeak: "d’une VMC Hygro A",
    description:
      "Dans les logements il est nécessaire de ventiler pour éliminer l’humidité, les odeurs de cuisines, de sanitaires par exemple mais aussi pour recharger l’air en oxygène.",
    jsxDescription: (
      <React.Fragment>
        <p>
          Dans les logements il est nécessaire de ventiler pour éliminer
          l’humidité, les odeurs de cuisines, de sanitaires par exemple mais
          aussi pour recharger l’air en oxygène.
        </p>
        <p>
          Sans ventilation mécanique contrôlée, la principale source de
          ventilation est l’ouverture des fenêtres ce qui a pour effet :
        </p>
        <p>
          En hiver de faire entrer de l’air froid dans le logement et qui
          implique donc une surconsommation de chauffage.
        </p>
        <p>
          En été de faire entrer de l’air chaud et ainsi créer de l’inconfort.
        </p>
        <p>
          La Ventilation mécanique Contrôlée (VMC) simple flux aspire l’air
          pollué du logement tout au long de la journée mais en faible quantité.
          Les orifices dans les fenêtres permettent de faire entrer de l’air dit
          « neuf » de l’extérieur pour remplacer l’air pollué qui a été extrait.
        </p>
        <p>
          La VMC Hygroréglable est une amélioration de la VMC standard et permet
          d'augmenter l'évacuation de l’air pollué quand les pièces de service
          (cuisines et salles de bain) sont utilisées, et de la diminuer lorsque
          l'air est sec.{" "}
        </p>
        <p>
          Pour le bâtiment K les travaux vont consister à installer des moteurs
          en toiture et des gaines (gros tuyaux) dans les réservations
          techniques des logements. Les murs des réservations techniques seront
          percés pour pouvoir raccorder les gaines aux bouches de ventilation.{" "}
        </p>
        <p>
          Pour les deux autres bâtiments (30-32 allée des orgues de Flandre et 7
          -9 Rue Mathis) les moteurs de la ventilation en toiture seront changés
          ainsi que l’ensemble des bouches dans les logements.
        </p>
      </React.Fragment>
    ),
    jsxNote: <p></p>,
    detailsTravaux: [
      {
        id: 1,
        emoji: "",
        detail: "(à determiner)",
      },
    ],
    thumbnail: "NC10Thumb.png",
    video: {
      url: "https://youtu.be/xd7Dil93BGY",
      width: 320,
      height: 400,
    },
    planMasse: "illustrationAttente.png",
    planMasseTexte: "(à déterminer)",
    duration: "(à déterminer)",
    periode: "(à déterminer)",
    tags: ["informative"],
    submitted: false,
    questions: {},
    comment: "",
  },
];
